import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GridLoader from 'react-spinners/GridLoader';
// import sections
import AllBlogs from '../components/sections/BlogPosts';
import TopBlogs from '../components/sections/TopBlogPosts';
import Cta from '../components/sections/Cta';

const Blog = () => {
  const [status, setStatus] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
      try {
        const response = await axios.get('/api/blog/get');
        localStorage.setItem("blogList", JSON.stringify(response.data));
        setStatus(true)
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle the error gracefully, e.g., show an error message to the user.
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <TopBlogs id="topBlogs"/>
      <AllBlogs id="allBlogs" topDivider />
      <Cta id="contact"split />
      {/* {
        status
        ? (
          <>
            <TopBlogs id="topBlogs"/>
            <AllBlogs id="allBlogs" topDivider />
            <Cta id="contact"split />
          </>
        )
        : (
          <div className="loader">
            <GridLoader color="#ff0632" size={40} />
          </div>
        )
      } */}
    </>
  );
}

export default Blog;
