import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SectionTilesProps } from '../utils/SectionProps';
import axios from 'axios';
import classNames from 'classnames';
import GridLoader from 'react-spinners/GridLoader';
import SectionHeader from '../components/sections/partials/SectionHeader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const propTypes = {
    ...SectionTilesProps.types
  }
  
  const defaultProps = {
    ...SectionTilesProps.defaults
  }

const Blog = ({
    className,
    blogOne,
    blogTwo,
    blogThree,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
  }) => {
  const [status, setStatus] = useState(false);
  const { postId } = useParams(); // Get the postId from the URL
  const [blogData, setBlogData] = useState({
    postId: '',
    image: '',
    content: '',
    tags: [],
    linkedinUrl: '',
  });

  useEffect(() => {
    const fetchData = async () => {
    try {
      const response = await axios.get('/api/blog/get/' + postId);
      console.log('API Response blogView:', response.data);
      setBlogData(response.data[0])
      setStatus(true)
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle the error gracefully, e.g., show an error message to the user.
    }
  };

  fetchData();
}, []);

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );
  
  const sectionHeader = {
    container: window.innerWidth >= 896 ? "container-sm" : (window.innerWidth >= 620 ? "container-xs" : ( window.innerWidth >= 420 ? "container-xss" : "container-xsss")),
    title: blogData.title,
    paragraph: blogData.elements
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className="loader-container">
          {status ? (
            <div className={innerClasses}>
              <SectionHeader data={sectionHeader} className="center-content"/>
              <div className="tags-container">
                <Link to='/blog'>
                    <button className="button button-primary button-wide-mobile button-sm">Go Back</button>
                </Link>
                <div className="tags">
                    {blogData.tags.map((tag, index) => (
                    <span key={index} className="tag tag-primary tag-wide-mobile tag-sm">
                        {tag}
                    </span>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="loader">
              <GridLoader color="#ff0632" size={40} />
            </div>
          )}
        </div>
      </div>
    </section>
  );  
}

Blog.propTypes = propTypes;
Blog.defaultProps = defaultProps;

export default Blog;
