import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const Projects = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const[status, setStatus] = useState('');
  const[update, setUpdate] = useState('');
  const[details, setDetails] = useState('');
  const[clsname, setClsname] = useState('');

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner text-color-dark-light',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap text-color-dark-light',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    container: "container-xs",
    title: 'Our goals and projects',
    paragraph: 'We\'re driven by audacious goals and a relentless pursuit of innovation. Our journey is marked by a tapestry of projects, each a testament to our commitment to shaping the future of technology.'
  };

  const sectionHeader2 = {
    container: "container-xs",
    title: 'Effluence',
    paragraph: 'Empowering Appointments, Effortless Workflow'
  };


  useEffect(() => {
    axios.get('/api/projects/effluence')
      .then((response) => {
        setStatus(response.data[0].status);

        if(status==="TO DO") setClsname('text-xxs text-color-error fw-600 tt-u mb-8');
        else if(status==="IN PROGRESS") setClsname('text-xxs text-color-warning fw-600 tt-u mb-8');
        else setClsname('text-xxs text-color-success fw-600 tt-u mb-8')

        setUpdate(response.data[0].update);
        setDetails(response.data[0].details);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        // Handle the error gracefully, e.g., show an error message to the user.
      });
  });

  // window.onload = async () => {
  //     promiseOfSomeData
  //     console.log(status+ '\n' + update+ url);
  // };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <SectionHeader data={sectionHeader2} tag='h3' link="" className="center-content " />
          <div className={splitClasses}>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className={clsname}>
                  Status: {status}
                  </div>
                <h3 className="mt-0 mb-12 text-color-dark-light">
                  Effluence
                  </h3>
                <h5 className="m-0 text-color-heading text-color-dark-light">
                  Bug / Update:
                </h5>
                <p className="m-0 text-color-dark-light" style={{color:"#9CA9B3"}}>{update}</p> 
                <h5 className="m-0 text-color-heading text-color-dark-light">
                  Details:
                </h5>
                <p className="m-0 text-color-dark-light" style={{color:"#9CA9B3"}}>{details}</p> 
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/effluence.svg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Projects.propTypes = propTypes;
Projects.defaultProps = defaultProps;

export default Projects;
