import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string
  }).isRequired,
  children: PropTypes.node,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  link:PropTypes.string
}

const defaultProps = {
  children: null,
  tag: 'h2',
  link: null,
}

const SectionHeader = ({
  className,
  data,
  children,
  tag,
  link,
  ...props
}) => {

  const classes = classNames(
    'section-header',
    className
  );

  const Component = tag;
  const Link = link

  return (
    <>
      {(data.title || data.paragraph) &&
        <div
          {...props}
          className={classes}
        >
          <div className={data.container}>
            {children}
            <a href={Link}>
            {data.title &&
              <Component className={
                classNames(
                  'mt-0 text-color-dark-light',
                  data.paragraph ? 'mb-16' : 'mb-0'
                )}>{data.title}</Component>
            }
            </a>
            {data.paragraph &&
              data.paragraph instanceof Array ?
              data.paragraph.map((item) => (
                <>
                  <div dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                  <div dangerouslySetInnerHTML={{ __html: item.sectionContent }} />
                  <div dangerouslySetInnerHTML={{ __html: item.image }} />
                </>
              ))
              :
              <p className="text-color-dark-light">{data.paragraph}</p>
            }
          </div>
        </div>
      }
    </>
  );
}

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;