import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Team = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    container: "container-xs",
    title: 'Our team',
    paragraph: 'Driven by a shared passion for creating meaningful tech solutions, we innovate with purpose and drive positive change.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content-costin">
                  <p className="text-sm mb-0">
                    Chief Executive Officer & Technical Lead
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Costin Teodorescu</span>
                    <a href="https://www.linkedin.com/in/costin-teodorescu/" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon className='testimonial-icon-social' icon={faLinkedinIn} />
                    </a>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content-stefan">
                  <p className="text-sm mb-0">
                    Frontend Architect & Frontend Framework Specialist
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Stefan Simion</span>
                  <a href="https://www.linkedin.com/in/stefan-simion-79a847225/" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon className='testimonial-icon-social' icon={faLinkedinIn} />
                    </a>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content-david">
                  <p className="text-sm mb-0">
                    DevOps Engineer & Server Engineer
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">David Fanaru</span>
                  <a href="https://www.linkedin.com/in/fanaru-david-mihail/" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon className='testimonial-icon-social' icon={faLinkedinIn} />
                    </a>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content-tudor">
                  <p className="text-sm mb-0">
                    Corporate Counsel & Tax Manager
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Tudor Alexandrescu</span>
                  <a href="https://www.linkedin.com/in/tudor-alexandrescu/" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon className='testimonial-icon-social' icon={faLinkedinIn} />
                    </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;