import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Projects from '../components/sections/Projects';
import Team from '../components/sections/Team';
import Cta from '../components/sections/Cta';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01 mb-32" />
      <FeaturesTiles />
      <Projects id="projects" invertMobile topDivider imageFill className="illustration-section-02" />
      <Team id="team" topDivider />
      <Cta id="contact"split />
    </>
  );
}

export default Home;