import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';
import Image from '../elements/Image';

import styled from 'styled-components';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Blog = ({
  className,
  blogOne,
  blogTwo,
  blogThree,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const [blogList, setBlogList] = useState(JSON.parse(localStorage.getItem("blogList")));
  
  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const titlesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );
  
  const sectionHeader = {
    container: "container-xs",
    title: 'Top Blogs for Insightful Reading',
    paragraph: 'Explore handpicked blogs offering expert opinions and engaging narratives on trending topics and timeless discussions.'
  };
  
  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={titlesClasses}>

            
            { blogList
            ?
              blogList.slice(0, 3).map((data) =>
                (
                  <div className="tiles-item reveal-from-right">
                    <div className="tiles-item-inner">
                      <div className="testimonial-item-content">
                        <img src={data.image}></img>
                        <p className="text-sm mb-0 text-color-high">
                          {data.title}
                        </p>
                      </div>
                      <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                          <Link to={`/blog/${data.postId}`}>
                            <span className="testimonial-item-name text-color-high">View More</span>
                          </Link>
                          <a href={data.linkedinUrl} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className='testimonial-icon-social' icon={faLinkedinIn} />
                          </a>
                      </div>
                    </div>
                  </div>
                )
              )
              :
              <div>Loading</div>
            }

          </div>
        </div>
      </div>
    </section>
  );
}

Blog.propTypes = propTypes;
Blog.defaultProps = defaultProps;

export default Blog;